import { useQuery } from "@tanstack/react-query";
import { logisticsApi } from "./ApiClient";
import { generateQueryString } from "./Helpers";
import { scheduledShiftsSchema } from "@models/shifts";
import { format } from "@models/date-and-time";

async function fetchScheduledShiftsInCenter(from?: string, to?: string) {
  const scheduledShiftsResponse = await logisticsApi.get(
    `/schedule/center${generateQueryString({ from, to })}`,
  );
  const parsedShifts = scheduledShiftsSchema
    .parse(scheduledShiftsResponse.data)
    .sort((shift, othershift) =>
      shift.startDateTime > othershift.startDateTime ? 1 : -1,
    );
  return parsedShifts;
}

export const shiftKeys = {
  all: ["shifts"] as const,
  lists: () => [...shiftKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...shiftKeys.lists(), filters] as const,
};

export const useScheduledCenterShifts = (fromDate?: Date, toDate?: Date) => {
  const from = fromDate ? format(fromDate, "yyyy-MM-dd") : undefined;
  const to = toDate ? format(toDate, "yyyy-MM-dd") : undefined;
  return useQuery({
    queryKey: shiftKeys.list({
      from,
      to,
      center: true,
    }),
    queryFn: () => fetchScheduledShiftsInCenter(from, to),
  });
};
