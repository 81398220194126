import { logisticsApi } from "@/api/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { employee } from "@models/shifts";
import { useAuth } from "react-oidc-context";

export const fetchEmployee = async (employeeNumber: string) => {
  const employeeResponse = await logisticsApi.get(
    `/employees/${employeeNumber}`,
  );
  const parsedEmployee = employee.parse(employeeResponse.data);
  return parsedEmployee;
};

export const employeeKeys = {
  all: ["employees"] as const,
  lists: () => [...employeeKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...employeeKeys.lists(), { filters }] as const,
  detail: (id: string) => [...employeeKeys.all, id, "details"] as const,
};

export const useCurrentEmployee = () => {
  const { user } = useAuth();
  // `employee_number` is a custom claim, thus not supported by built in types, thus being asserted manually
  const employeeNumber = user ? (user.profile.employee_number as string) : null;
  return useQuery({
    // Fallback to `""` doesn't really matter except for typing. `enabled: false` ensures that the queryFn is not executed unless an employeeNumber exists
    queryKey: employeeKeys.detail(employeeNumber ?? ""),
    queryFn: () => fetchEmployee(employeeNumber ?? ""),
    enabled: Boolean(employeeNumber),
  });
};
