/* eslint-disable prefer-const */
// We have a pattern to use `let` in these files. Don't mess with it.

import { UserManager, type UserManagerSettings } from "oidc-client-ts";

let logisticsApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? "https://localhost:7240"
    : "https://logistics-dev.medoma.com";
let devicesUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_DEVICES_BACKEND
    ? "https://localhost:7240"
    : "https://devices-dev.medoma.com";
let apiClientId = "2c46ab04-22e8-47dc-9c50-10b8e5b9d399";
let mapboxApiUrl = "https://api.mapbox.com";
let mapboxPublicAccessToken =
  "pk.eyJ1IjoibWVkb21hIiwiYSI6ImNsN2Z6c2J0bTAwOHozd25xM3JlNzZ6bjUifQ.v2XsEAYwGo00Vk0XZteAZQ";
let patientApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? "https://localhost:7023"
    : "https://pas-dev.medoma.com";
let patientApiClientId = "9a7059a4-7ab2-4353-8604-3f1e9a1cce84";
let authApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_AUTH_BACKEND
    ? "https://localhost:5001"
    : "https://auth-dev.medoma.com";
let notificationApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_NOTIFICATION_BACKEND
    ? "https://localhost:7240/notification"
    : "https://logistics-dev.medoma.com/notification";
let pushyAppId = "675ad8c47a327a8229eef939";
let environment = import.meta.env.DEV ? "local" : "dev";

const hostname = window?.location?.hostname;

// Inspired by https://daveceddia.com/multiple-environments-with-react/
if (hostname === "go.medoma.com") {
  apiClientId = "d37df4ad-5193-4edc-86ab-0a9e4fd3915a";
  logisticsApiUrl = "https://logistics.medoma.com";
  patientApiClientId = "8c092f1a-8dea-456d-b25f-cd0bfd0306c3";
  patientApiUrl = "https://pas.medoma.com";
  authApiUrl = "https://auth.medoma.com";
  notificationApiUrl = "https://logistics.medoma.com/notification";
  pushyAppId = "675ada15377b179337a40445";
  environment = "prod";
  devicesUrl = "https://devices.medoma.com";
} else if (hostname === "go.test.medoma.com") {
  apiClientId = "d2180b38-ca38-4353-909a-54c9923facc6";
  logisticsApiUrl = "https://logistics-test.medoma.com";
  patientApiClientId = "c575327d-a9e2-4fde-a547-da63ed19345e";
  patientApiUrl = "https://pas-test.medoma.com";
  authApiUrl = "https://auth-test.medoma.com";
  notificationApiUrl = "https://logistics-test.medoma.com/notification";
  pushyAppId = "675aac7c377b179337a4043f";
  environment = "test";
  devicesUrl = "https://devices-test.medoma.com";
} else if (hostname === "go.demo.medoma.com") {
  apiClientId = "2b4076e8-75e5-4f04-aa26-50c3e0c51a1f";
  logisticsApiUrl = "https://logistics-demo.medoma.com";
  patientApiClientId = "be490833-d2a7-4e50-8858-ebf34a9fb0a3";
  patientApiUrl = "https://pas-demo.medoma.com";
  authApiUrl = "https://auth-demo.medoma.com";
  notificationApiUrl = "https://logistics-demo.medoma.com/notification";
  pushyAppId = "675ad9e6377b179337a40443";
  environment = "demo";
  devicesUrl = "https://devices-demo.medoma.com";
}

export const oidcConfig: UserManagerSettings = {
  authority: authApiUrl,
  client_id: "medoma-go",
  redirect_uri: `${window.location.origin}/ids-login`,
  scope:
    "patient.api logistic.api user_type mfa employee_profile issued_for_app openid",
};
export const userManager = new UserManager(oidcConfig);

export const {
  API_CLIENT_ID,
  LOGISTICS_API_URL,
  MAPBOX_API_URL,
  MAPBOX_PUBLIC_ACCESS_TOKEN,
  PATIENT_API_CLIENT_ID,
  PATIENT_API_URL,
  AUTH_API_URL,
  NOTIFICATION_API_URL,
  DEVICES_URL,
  PUSHY_APP_ID,
  ENVIRONMENT,
} = {
  API_CLIENT_ID: apiClientId,
  LOGISTICS_API_URL: logisticsApiUrl,
  MAPBOX_API_URL: mapboxApiUrl,
  MAPBOX_PUBLIC_ACCESS_TOKEN: mapboxPublicAccessToken,
  PATIENT_API_CLIENT_ID: patientApiClientId,
  PATIENT_API_URL: patientApiUrl,
  AUTH_API_URL: authApiUrl,
  NOTIFICATION_API_URL: notificationApiUrl,
  DEVICES_URL: devicesUrl,
  PUSHY_APP_ID: pushyAppId,
  ENVIRONMENT: environment,
};

export const HOSPITAL_COORDINATES = {
  longitude: 18.0206038,
  latitude: 59.3342792,
};
