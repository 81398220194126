import axios, {
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";
import {
  AUTH_API_URL,
  LOGISTICS_API_URL,
  MAPBOX_API_URL,
  NOTIFICATION_API_URL,
  PATIENT_API_URL,
  userManager,
} from "../Utils/EnvUtils";
import { User } from "oidc-client-ts";
import {
  isUnauthenticatedError,
  isUnauthorizedError,
} from "@/Utils/ErrorUtils";

const logisticsApi = axios.create({
  baseURL: LOGISTICS_API_URL,
});

const patientApi = axios.create({
  baseURL: PATIENT_API_URL,
});

const notificationApi = axios.create({
  baseURL: NOTIFICATION_API_URL,
});

const signinRedirect = async () => {
  userManager.signinRedirect({
    state: window.location.pathname,
    acr_values: "mfa",
  });
};

const getAuthenticatedUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${AUTH_API_URL}:medoma-go`,
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  if (user?.expired) {
    signinRedirect();
  }

  return user;
};

const injectBearerToken = (
  config: InternalAxiosRequestConfig,
  token: string | undefined,
) => {
  if (config && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const requestHandler = (config: InternalAxiosRequestConfig) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") return config;
  const unexpiredUser = getAuthenticatedUser();
  return injectBearerToken(config, unexpiredUser?.access_token);
};

const responseHandler = (response: AxiosResponse) => {
  return response;
};

const errorHandler = (error: unknown) => {
  // This typically happens if:
  // The token is expired, or
  // The token was forcibly invalidated, e.g. by closing the session from an admin panel
  if (isUnauthenticatedError(error)) {
    signinRedirect();
  }

  // This typically happens if:
  // The token is still valid, but the MFA timestamp is too old
  // This should only happen if accepted MFA age is shorter than token lifetime
  if (isUnauthorizedError(error)) {
    signinRedirect();
  }

  return Promise.reject(error);
};

logisticsApi.interceptors.request.use(requestHandler);
logisticsApi.interceptors.response.use(responseHandler, errorHandler);
patientApi.interceptors.request.use(requestHandler);
patientApi.interceptors.response.use(responseHandler, errorHandler);
notificationApi.interceptors.request.use(requestHandler);
notificationApi.interceptors.response.use(responseHandler, errorHandler);

const mapboxApi = axios.create({
  baseURL: MAPBOX_API_URL,
});

const getMapboxToken = () =>
  "pk.eyJ1IjoibWVkb21hIiwiYSI6ImNsN2Z6c2J0bTAwOHozd25xM3JlNzZ6bjUifQ.v2XsEAYwGo00Vk0XZteAZQ";

mapboxApi.interceptors.request.use((config) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") return config;
  const token = getMapboxToken();
  if (config && config.url) {
    config.url = config.url.concat(`&access_token=${token}`);
  }
  return config;
});

export { mapboxApi, patientApi, logisticsApi, notificationApi };
