import { t } from "@lingui/core/macro";
import { deduplicatePrimitiveArray } from "@/Utils/arrayUtils";
import { dateSchema } from "@models/date-and-time";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { logisticsApi } from "./ApiClient";
import { generateQueryString } from "./Helpers";
import {
  createDeletedPatient,
  fetchPatient,
  fetchPatients,
  resolvePatient,
} from "./Patients";
import {
  visitSchemaWithPatientId,
  visitSchema,
  type IVisitWithPatientId,
} from "@models/visits";
import { timeOfDayDictionary, timeOfDaySchema } from "@models/activities";
import { i18n } from "@lingui/core";
import { type IPatient } from "@models/patients";

const routeStatusSchema = z.enum(["draft", "ongoing", "finished"]);
const routeSchemaWithPatientId = z.object({
  id: z.string(),
  name: z.string(),
  date: z.string(),
  finishedAt: dateSchema.nullish(),
  status: routeStatusSchema,
  visits: z.array(visitSchemaWithPatientId),
  timespan: z
    .object({
      from: dateSchema,
      to: dateSchema,
    })
    .nullish(),
});
export type IRouteWithPatientId = z.infer<typeof routeSchemaWithPatientId>;

export const routeSchema = routeSchemaWithPatientId
  .omit({ visits: true })
  .extend({ visits: z.array(visitSchema) });
export type IRoute = z.infer<typeof routeSchema>;

export const optionallyNamedRouteWithPatientIdSchema = routeSchemaWithPatientId
  .omit({ name: true })
  .extend({ name: z.string().nullish() });
const optionallyNamedRoutesWithPatientIdsSchema = z.array(
  optionallyNamedRouteWithPatientIdSchema,
);
const optionallyNamedRouteSchema = routeSchema
  .omit({ name: true })
  .extend({ name: z.string().nullish() });
const optionallyNamedRoutesSchema = z.array(optionallyNamedRouteSchema);

export async function finishRoute(routeId: string) {
  await logisticsApi.post(`/routes/${routeId}/finish`);
}

const timeOfDay = (time: "Any" | Date) => {
  if (time === timeOfDaySchema.Values.Any) {
    return i18n._(timeOfDayDictionary.Any.short);
  }
  const hour = time.getHours();

  if (hour >= 4 && hour < 10) {
    return t`Morgon`;
  }

  if (hour >= 10 && hour < 12) {
    return t`Förmiddag`;
  }

  if (hour >= 12 && hour < 17) {
    return t`Eftermiddag`;
  }

  if (hour >= 17 && hour <= 22) {
    return t`Kväll`;
  }

  if (hour >= 23 || (hour >= 0 && hour < 4)) {
    return t`Natt`;
  }

  return t`[tid på dagen]`;
};

const generateRouteName = (
  route: z.infer<typeof optionallyNamedRouteSchema>,
) => {
  const { visits } = route;
  const occurrences = visits.flatMap((visit) => visit.occurrences);
  if (visits.length === 0) {
    return t`Ny rutt`;
  }
  if (!occurrences[0]) {
    return t`Ny rutt`;
  }
  const specificTimeOccurrences = occurrences.filter(
    (occurrence) => occurrence.timeOfDay === "Specific",
  );
  if (!specificTimeOccurrences[0]) {
    return i18n._(timeOfDayDictionary.Any.short);
  }
  return timeOfDay(
    specificTimeOccurrences[0].timeOfDay === timeOfDaySchema.Values.Any
      ? timeOfDaySchema.Values.Any
      : specificTimeOccurrences[0].start,
  );
};

const nameRoutes = (
  possiblyNamelessRoutes: z.infer<typeof optionallyNamedRoutesSchema>,
) => {
  return possiblyNamelessRoutes.map((route) => nameRoute(route));
};

const nameRoute = (
  possiblyNamelessRoute: z.infer<typeof optionallyNamedRouteSchema>,
) => {
  let { name } = possiblyNamelessRoute;
  if (!name) {
    name = generateRouteName(possiblyNamelessRoute);
  }
  return {
    ...possiblyNamelessRoute,
    name,
  };
};

const _possiblyNamelessRoutesWithPatientIdsSchema = z.array(
  routeSchemaWithPatientId.omit({ name: true }),
);
const patientIdsInRoutes = (
  routesWithPatientIds: z.infer<
    typeof _possiblyNamelessRoutesWithPatientIdsSchema
  >,
) =>
  deduplicatePrimitiveArray(
    routesWithPatientIds
      .flatMap(({ visits }) => visits)
      .flatMap(({ patientId }) => patientId),
  );

export const parseAndEnrichRoutes = async (probableRoutes: unknown) => {
  const possiblyNamelessRoutesWithPatientIds =
    optionallyNamedRoutesWithPatientIdsSchema.parse(probableRoutes);

  if (possiblyNamelessRoutesWithPatientIds.length === 0) {
    return possiblyNamelessRoutesWithPatientIds as [];
  }

  const patientIds = patientIdsInRoutes(possiblyNamelessRoutesWithPatientIds);
  const patients = await fetchPatients({ patientIds });
  const possiblyNamelessRoutes = optionallyNamedRoutesSchema.parse(
    possiblyNamelessRoutesWithPatientIds.map((route) => ({
      ...route,
      visits: route.visits.map((visit) => ({
        ...visit,
        patient: resolvePatient({ patientId: visit.patientId, patients }),
      })),
    })),
  );
  const namedRoutes = nameRoutes(possiblyNamelessRoutes);
  return namedRoutes;
};

export const routeKeys = {
  all: ["routes"] as const,
  lists: () => [...routeKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...routeKeys.lists(), { filters }] as const,
  detail: (id: string) => [...routeKeys.all, id, "details"] as const,
};

export const VISIT_KEY_ROOT = "visits";
export const visitKeys = (routeId: string) => ({
  all: () => [...routeKeys.all, routeId, VISIT_KEY_ROOT] as const,
  lists: () => [...visitKeys(routeId).all(), "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...visitKeys(routeId).lists(), { filters }] as const,
  detail: (id: string) => [...visitKeys(routeId).all(), id, "details"] as const,
});

export async function fetchRoute(routeId: string) {
  const routeResponse = await logisticsApi.get(`/routes/${routeId}`);
  const parsedRoute = optionallyNamedRouteWithPatientIdSchema.parse(
    routeResponse.data,
  );
  const patientIds = patientIdsInRoutes([parsedRoute]);
  const patients = await fetchPatients({ patientIds });
  const possiblyNamelessRoute = optionallyNamedRouteSchema.parse({
    ...parsedRoute,
    visits: parsedRoute.visits.map((visit) => ({
      ...visit,
      patient: resolvePatient({ patientId: visit.patientId, patients }),
    })),
  });
  const namedRoute = nameRoute(possiblyNamelessRoute);
  const route = namedRoute;

  return route;
}

export const useUpcomingVisit = (routeId: string) => {
  return useQuery({
    // It doesn't understand that the string is the routeId
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: visitKeys(routeId).list({ status: "upcoming" }),
    queryFn: () =>
      fetchRoute(routeId).then((route) => {
        const ongoingVisits = route.visits.filter(
          ({ status }) => status === "travellingTo" || status === "ongoing",
        );
        return ongoingVisits.length === 0
          ? (route.visits.find((visit) => visit.status === "planned") ?? null)
          : null;
      }),
  });
};

export const useOngoingVisit = (routeId: string) => {
  return useQuery({
    // It doesn't understand that the string is the routeId
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: visitKeys(routeId).list({ status: "ongoing" }),
    queryFn: () => {
      return fetchRoute(routeId).then((route) => {
        return (
          route.visits.find(
            ({ status }) => status === "travellingTo" || status === "ongoing",
          ) ?? null
        );
      });
    },
  });
};

const enrichVisit = ({
  visit,
  patient,
  routeId,
}: {
  visit: IVisitWithPatientId;
  patient: IPatient;
  routeId: string;
}) => {
  const visitWithPatient = { ...visit, patient };
  const visitWithRouteReference = { ...visitWithPatient, routeId: routeId };
  return visitWithRouteReference;
};

async function fetchVisit(routeId: string, visitId: string) {
  const visitResponse = await logisticsApi.get(
    `/routes/${routeId}/visits/${visitId}`,
  );
  const parsedVisit = visitSchemaWithPatientId.parse(visitResponse.data);
  try {
    const patient = await fetchPatient(parsedVisit.patientId);
    return enrichVisit({ visit: parsedVisit, patient, routeId });
  } catch (_e) {
    const patient = createDeletedPatient(parsedVisit.patientId);
    return enrichVisit({ visit: parsedVisit, patient, routeId });
  }
}

export const useVisit = (routeId: string, visitId: string) => {
  return useQuery({
    // It doesn't understand that the string is the routeId
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: visitKeys(routeId).detail(visitId),
    queryFn: () => fetchVisit(routeId, visitId),
  });
};

export async function fetchEmployeesRoutes(date: string) {
  const queryString = generateQueryString({
    date,
  });
  const routesResponse = await logisticsApi.get(
    `/ambulating/routes${queryString}`,
  );
  const parsedRoutes = await parseAndEnrichRoutes(routesResponse.data);
  return parsedRoutes;
}

export async function startTravellingToVisit(routeId: string, visitId: string) {
  await logisticsApi.post(
    `/routes/${routeId}/visits/${visitId}/startTravellingTo`,
  );
}

export async function startVisit(routeId: string, visitId: string) {
  await logisticsApi.post(`/routes/${routeId}/visits/${visitId}/start`);
}

export async function finishVisit(routeId: string, visitId: string) {
  await logisticsApi.post(`/routes/${routeId}/visits/${visitId}/finish`);
}

export const cancelTravellingToVisit = async (
  routeId: string,
  visitId: string,
) => {
  await logisticsApi.post(
    `/routes/${routeId}/visits/${visitId}/revertTravellingTo`,
  );
};
